<template>
    <div>
        <nh-title title="数据统计"></nh-title>
        <div style="display: flex">
            <div><nh-menu active-selected="StatisticList"></nh-menu></div>
            <div class="list-container">
                <div class="search-bar-wrapper">
                    <div class="search-items">
                        <div v-if="isAdmin" class="search-item">
                            <el-select v-model="searchParams.nurseIds" clearable multiple placeholder="专员"
                                       @change="doNurseChange">
                                <el-option v-for="item in nurseList" :key="item.id" :label="item.name"
                                           :value="item.id"></el-option>
                            </el-select>
                        </div>
                        <div class="search-item">
                            <el-select v-model="searchParams.areaIds" multiple placeholder="DM" clearable @change="doAreaChange">
                                <el-option
                                        v-for="item in areaList"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                        </div>
                        <div class="search-item">
                            <nh-cascader :props="cascaderProps" :options="hospitalList" v-model="searchParams.hospitals" placeholder="医院"
                                         ></nh-cascader>
                        </div>
                        <div class="search-item">
                            <el-select v-model="searchParams.syz" clearable placeholder="适应症"
                                        class="select-header">
                                <el-option v-for="item in jcjbzdList" :key="item.id" :label="item.name"
                                           :value="item.id"></el-option>
                            </el-select>
                        </div>
                        <div class="search-item">
                            <el-date-picker v-model="searchParams.searchDate"
                                            type="daterange"
                                            class="rzrq-header"
                                            value-format="yyyy-MM-dd"
                                            range-separator="-"
                                            start-placeholder="开始日期"
                                            end-placeholder="结束日期">
                            </el-date-picker>
                        </div>
                        <div class="search-item">
                            <el-button class="ok-btn" @click="fetchData()">确定</el-button>
                        </div>
                    </div>
                    <div class="add-button">
                        <img src="https://yitian-cms.oss-cn-hangzhou.aliyuncs.com/img/c6bd0083373085ba179b4be5f373d12b.png"
                             @click="downloadExcel"/>
                    </div>
                </div>

                <el-table class="list-table-container"
                          :data="statisticList"
                          v-loading="loading"
                          element-loading-text="统计中,请稍后"
                          stripe>
                    <el-table-column v-for="(title, index) in colTitles" :key="index"
                            :prop="title.id"
                            :label="title.name">
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>

<script>
import NhTitle from "@/components/patient/components/nh-title";
import NhMenu from "@/components/patient/components/nh-menu";
import NhCascader from "@/components/patient/components/nh-cascader";
import surveyUtil from "@/common/surveyUtil";
import cvsUtil from "@/common/cvsUtil";
export default {
    name: "StatisticList",
    components: {NhCascader, NhMenu, NhTitle},
    data() {
        return {
            searchParams:{},
            jcjbzdList:[],
            statisticList:[],
            nurseList:[],
            areaList:[],
            hospitalList:[],
            cascaderProps: {
                multiple: true,
                label: 'name',
                value: 'name'
            },
            loading: false,
            hideLoading: true,
            colTitles: []
        }
    },
    computed: {
        isAdmin() {
            return this.$sess.getCurrentDoctor() && this.$sess.getCurrentDoctor().admin
        }
    },
    created() {
        this.prepareCol()
        // this.fetchData()
        this.fetchNurses()
        this.fetchAreas()
        this.fetchHospitalByAreas()
        this.fetchSurveyInfos()
    },
    methods:{
        prepareCol() {
            let titles = [{name: '入组总数',id:'rzCnt'},{name: '面对面入组数',id:'mdmCnt'},{name: '远程入组数',id:'ycCnt'},
                {name: '远程2患者数',id:'yc2Cnt'},{name: '脱落患者数',id:'tlCnt'},{name: '脱落患者平均DOT',id:'tlDotAvg'},
                {name: '共用药支数',id:'yyCnt'}, {name: '完成引导注射数',id:'zsydCnt'},{name: '产生总随访数',id:'sfTaskStartCnt'},
                {name: '完成总随访数',id:'sfTaskFinishCnt'},{name: '关注公众号数',id:'gzgzhCnt'},{name: '上报AE数',id:'aeCnt'},
                {name: '未完成任务',id:'taskNotFinishCnt'},{name: '过期任务',id:'taskExpiredCnt'}]
            if (this.isAdmin) {
                titles.unshift({name: '专员',id:'doctorName'})
            }
            this.colTitles = titles
        },
        fetchData() {
            if (this.searchParams.nurseIds) {
                const doctorIds = this.nurseList.filter(nurse=>this.searchParams.nurseIds.includes(nurse.id)).map(nurse=>nurse.doctorId)
                this.searchParams.doctorIds = doctorIds
            }else {
                this.searchParams.doctorIds = []
            }
            this.loading = true
            this.$httpUtil.post(this.$urlConstant.nuohua.statisticList,
                this.searchParams,
                (responseData) => {
                    this.statisticList = responseData.data
                    if (this.isAdmin) {
                        for (const statistic of this.statisticList) {
                            statistic.doctorName = this.findNurseNameByDoctorId(statistic.doctorId)
                        }
                    }
                    this.calcAll()
                    this.loading = false
                },
                this
            )
        },
        calcAll() {
            if (this.statisticList.length > 1) {
                //处理tlDotAvg,算总平均,不是和
                let allTlAllDot = 0
                for (const statistic of this.statisticList) {
                    const tlAllDot = statistic['tlCnt'] * statistic['tlDotAvg']
                    allTlAllDot += tlAllDot
                }
                //其他都是算和
                let allStatistic = {}
                for (const title of this.colTitles) {
                    const id = title.id
                    let cnt = 0
                    for (const statistic of this.statisticList) {
                        cnt += statistic[id]
                    }
                    allStatistic[id] = cnt
                }
                allStatistic.doctorName = '总计'
                allStatistic['tlDotAvg'] = (allTlAllDot/allStatistic['tlCnt']).toFixed(2)
                this.statisticList.push(allStatistic)
            }
        },
        downloadExcel() {
            if (this.loading) {
                this.$message.warning('请先等数据统计完')
                return
            }
            let dataList = [this.colTitles.map(title=>title.name)]
            for (const statistic of this.statisticList) {
                let rowData = []
                for (const title of this.colTitles) {
                    rowData.push(statistic[title.id])
                }
                dataList.push(rowData)
            }
            const downloadRequest = {
                request: this.searchParams,
                dataList: dataList
            }
            this.$httpUtil.postDownload(this.$urlConstant.nuohua.statisticExcel,
                downloadRequest,
                (response) => {
                    console.log(response)
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const fileName = response.headers['content-disposition'].substring('attachment; filename='.length)
                    console.log(fileName)
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', fileName); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                },
                this
            )
        },
        fetchSurveyInfos() {
            this.$httpUtil.get(this.$urlConstant.nuohua.defaultSurveyList, (res) => {
                const rzSurvey = res.data.rz
                this.jcjbzdList = surveyUtil.findPropertyById(rzSurvey, 'syz').options.sort((a, b)=>{
                    return a > b ? 1 : -1
                })
            }, this)
        },
        fetchNurses() {
            if (this.isAdmin) {
                this.$httpUtil.get(this.$urlConstant.nuohua.nurseList, (res) => {
                    this.nurseList = res.data
                    this.nurseList.unshift({id: '#', name: '全选'})
                }, this)
            }
        },
        fetchAreas(nurseIds) {
            const suffex = (nurseIds && nurseIds.length > 0) ? ('/' + nurseIds.join()):''
            this.$httpUtil.get(this.$urlConstant.nuohua.statisticAreaList + suffex,(res)=>{
                this.areaList = res.data
            }, this)
        },
        fetchHospitalByAreas(areaIds) {
            const suffex = (areaIds && areaIds.length > 0) ? ('/areas/' + areaIds.join()):''
            this.$httpUtil.get(this.$urlConstant.nuohua.statisticHospitalList + suffex,(res)=>{
                this.hospitalList = JSON.parse(res.data)
            }, this)
        },
        fetchHospitalByNurses(nurseIds) {
            const suffex = (nurseIds && nurseIds.length > 0) ? ('/nurses/' + nurseIds.join()):''
            this.$httpUtil.get(this.$urlConstant.nuohua.statisticHospitalList + suffex,(res)=>{
                this.hospitalList = JSON.parse(res.data)
            }, this)
        },
        doNurseChange(nurseIds) {
            if (nurseIds.includes('#')) {
                nurseIds = this.nurseList.filter(nurse=>nurse.id !== '#').map(nurse=>nurse.id)
                this.searchParams.nurseIds = nurseIds
            }
            this.searchParams.areaIds = []
            this.searchParams.hospitals = []
            this.fetchAreas(nurseIds)
            this.fetchHospitalByNurses(nurseIds)
            // this.fetchData()
        },
        doAreaChange(areaIds) {
            this.searchParams.hospitals = []
            this.fetchHospitalByAreas(areaIds)
            // this.fetchData()
        },
        findNurseNameByDoctorId(doctorId) {
            const nurse = this.nurseList.find(nurse=>nurse.doctorId === doctorId)
            return nurse ? nurse.name : '全部'
        },
        exportClick() {
            let array = []
            array.push(['入组总数','面对面入组数','远程入组数','远程2患者数','脱落患者数','脱落患者平均DOT','共用药支数',
                '完成引导注射数','产生总随访数','完成总随访数','关注公众号数','上报ae数','未完成任务','过期任务'])
            for (const row of this.statisticList) {
                array.push([row.rzCnt,row.mdmCnt,row.ycCnt,row.yc2Cnt,row.tlCnt,row.tlDotAvg.toFixed(2),row.yyCnt,
                    row.zsydCnt,row.sfTaskStartCnt,row.sfTaskFinishCnt,row.gzgzhCnt,row.aeCnt,row.taskNotFinishCnt,row.taskExpiredCnt])
            }
            cvsUtil.download(array)
        }
    }
}
</script>

<style scoped>
.search-bar-wrapper {
    display: flex;
    margin-bottom: 30px;
    justify-content: space-between;
}
.search-items {
    display: flex;
    align-items: center;
}
.search-item {
    margin-right: 20px;
}
.ok-btn {
    background: #556BE5;
    border-radius: 8px;
    width: 92px;
    height: 30px;
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #FFFFFF;
    letter-spacing: 0;
    line-height: unset;
    padding: unset;
}
</style>