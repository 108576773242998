export default {
    download(array) {
        const csvString = array.map(item=>{return this.processRow(item)}).join("\n")
        //前置的"\uFEFF"为“零宽不换行空格”，可处理中文乱码问题
        const blob = new Blob(["\uFEFF" + csvString], { type: 'text/csv;charset=utf-8;' })
        const a = document.createElement('a');
        a.download = '统计.cvs';//这里替换为你需要的文件名
        a.href = URL.createObjectURL(blob);
        a.click();
    },
    processRow(row) {
        var finalVal = '';
        for (var j = 0; j < row.length; j++) {
            var innerValue = row[j] === null ? '' : row[j].toString();
            if (row[j] instanceof Date) {
                innerValue = row[j].toLocaleString();
            }
            var result = innerValue.replace(/"/g, '""');
            if (result.search(/("|,|\n)/g) >= 0)
                result = '"' + result + '"';
            if (j > 0)
                finalVal += ',';
            finalVal += result;
        }
        return finalVal;
    }
}